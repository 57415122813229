import {useNavigate} from "react-router-dom";
import axios from "axios";
import paths from "../paths";

const useAxiosInterceptors = () => {
    const navigate = useNavigate();
    const refreshToken = async () => {
        try {
            const response = await axios.post('/api/1.0/auth/refresh', {}, {withCredentials: true});
            if (response.status === 200) {
                localStorage.setItem('jwttoken', response.data.token);
                return response.data.token;
            }
        } catch (error) {
            // localStorage.removeItem('jwttoken');
            navigate(paths.login);
            throw error;
        }
    };

    axios.interceptors.request.use(
        (config) => {
            if (localStorage.getItem('jwttoken')) {
                if (config.headers) {
                    config.headers['Authorization'] = `Bearer ${localStorage.getItem('jwttoken')}`;
                }
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    // axios.interceptors.response.use(
    //     (response) => {
    //         return response;
    //     },
    //     async (error) => {
    //         const originalRequest = error.config;
    //         if (error.response?.status === 401 && !originalRequest._retry) {
    //             localStorage.removeItem('jwttoken');
    //
    //             originalRequest._retry = true;
    //             try {
    //                 const newToken = await refreshToken();
    //                 originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
    //                 return axios(originalRequest);
    //             } catch (refreshError) {
    //                 return Promise.reject(refreshError);
    //             }
    //         }
    //         return Promise.reject(error);
    //     }
    // );

    axios.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            if (error.response?.status === 401) {
                localStorage.removeItem('jwttoken');
                navigate(paths.login);
            }
            return Promise.reject(error);
        }
    );

};

export default useAxiosInterceptors;