import {ChangePasswordDTO, SetNewPasswordDTO, SetNewPasswordInput} from "./setNewPassword.types";
import axios from "axios";

type SetNewPasswordServiceResponse = Promise<
    | { success: true }
    | { success: false, errorMessage: string }
>

const changeNewPasswordService = async (inputData: ChangePasswordDTO): SetNewPasswordServiceResponse => {
    try {
        const {status} = await axios.post('/api/1.0/auth/password/change-password', inputData);
        if (!status || status >= 300) throw 'Coś poszło nie tak';
        return {success: true};
    } catch (e) {
        return {success: false, errorMessage: typeof e === 'string' ? e : 'Coś poszło nie tak'};
    }
}


export default changeNewPasswordService