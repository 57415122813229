import React from "react";
import clx from "classnames";
import styles from "./MentorNewsletter.module.scss";
import {EmptyState} from "src/components/EmptyState";
import {MentorNewsletterProps, MentorNewsletterSubscription} from "./MentorNewsletterSubscription";


export const MentorNewsletter = ({
                                     isDesktop,
                                     className,
                                     mentorNewsletterData,
                                 }: MentorNewsletterProps) => {
    const showNewsletter = mentorNewsletterData && mentorNewsletterData.newsletterActive;

    return (
        <div className={clx(styles.linksSection, className)}>
            <h5 className={styles.newsletterTitle}>Newsletter</h5>
            {showNewsletter ? (
                <MentorNewsletterSubscription mentorNewsletterData={mentorNewsletterData} />
            ) : (
                <EmptyState
                    text="Aktualnie nie posiadam w swojej ofercie newslettera"
                    className={styles.emptyProductState}
                />
            )}
        </div>


    );
};
