import {Dispatch} from 'react';
import {NavigateFunction} from 'react-router-dom';

import {parseUserFromJwt} from './parseUserFromJwt';
import paths from "../paths";
import {loginGoogleUser, loginUser} from "@services/auth/authenticationService";
import axios from "axios";

type SuccessResponse = { success: true, userData: ReturnType<typeof parseUserFromJwt> & { id: string } };
type ErrorResponse = { success: false, errorMessage: string };

type LoginReturn = Promise<SuccessResponse | ErrorResponse>;

export const loginUserByEmail = async (email: string, password: string, rememberMe: boolean): LoginReturn => {
    try {
        const response = await loginUser(email, password);

        const status = response?.request?.status;

        if (status === 200 && response.data?.accessToken) {
            const accessToken = response.data.accessToken;
            //TODO - use refreshToken from cookie when access token expires
            return await getStoreAndReturnUserData(accessToken, email);

        } else {
            return {success: false, errorMessage: 'Nieprawidłowy adres e-mail lub hasło.'};
        }
    } catch (err: any) {
        return {success: false, errorMessage: 'Wystąpił nieznany problem z zalogowaniem.'};
    }
};

const getStoreAndReturnUserData = async (userJWT: string, email: string): LoginReturn => {
    const userData = parseUserFromJwt(userJWT);
    if (!userData) {
        return {success: false, errorMessage: 'Nie udało się pobrać danych użytkownika'};
    }

    localStorage.setItem('jwttoken', userJWT);

    return {
        success: true,
        userData: {
            id: userData.id,
            email: userData.email,
            role: userData.role,
            username: userData.username,
            firstName: userData.firstName,
            stripeIntegrationStatus: userData.stripeIntegrationStatus,
        },
    };
};


export const loginUserByGoogle = async (email: string, token: string): LoginReturn => {
    try {
        const {data: {body: userJWT}} = await loginGoogleUser(token);
        return getStoreAndReturnUserData(userJWT, email);
    } catch (err) {
        return {
            success: false,
            errorMessage: typeof err === 'string' ? err : 'Wystąpił problem z zalogowaniem przez Google'
        };
    }
};

export const logout = (dispatch: Dispatch<any>, navigate: NavigateFunction, notifyServer = true) => {
    const jwtToken = localStorage.getItem('jwttoken');
    if (notifyServer && jwtToken) {
        axios.post('/api/1.0/auth/logout', {},
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${jwtToken}`,
                },
            }
        ).then(() => {
            console.log('Successfully logged out');
        }).catch((err) => {
            console.error('Error during server logout:', err);
        });
    }

    // delete token from localstorage
    localStorage.removeItem('jwttoken');

    // reset application state
    dispatch({type: 'LOGOUT'});

    // redirect to login page
    navigate(paths.login);
};
