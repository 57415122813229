import {SessionCalendarEventResponse} from "@customTypes/sessionCalendarEventResponse";
import {Slot} from "@customTypes/slot";
import {convertToMenteeTime, formatTime, prepareEvent} from "@services/calendar/timeZoneService";
import {CalendarSlot} from "@customTypes/calendarSlot";
import {CalendarEvent} from "../../components/WeeklyCalendarPicker/WeeklyCalendarPicker";

export const dataParser = (dtoList: CalendarSlot[]): CalendarEvent[] => {
    const menteeTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const events: CalendarEvent[] = [];

    dtoList.forEach((item) => {
        try {
            const mentorTimeZone = item.mentorTimeZone || "UTC";
            const mentorDate = new Date(item.sessionDate);
            const [hour, minute] = item.hour.split(":").map(Number);
            mentorDate.setHours(hour, minute, 0, 0);

            const menteeDate = convertToMenteeTime(mentorDate, mentorTimeZone, menteeTimeZone);
            const title = formatTime(menteeDate);

            const event = prepareEvent(title, menteeDate, item);

            events.push(event);
        } catch (error) {
            console.error("Slot processing error:", item, error);
        }
    });

    return events;
};
