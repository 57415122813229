import axios from "axios";

import {CalendarSlot} from "@customTypes/calendarSlot";
import {SessionCalendarEventResponse} from "@customTypes/sessionCalendarEventResponse";
import {dataParser} from "@services/calendar/dataParserService";

/**
 * Fetches calendar session for a specific mentor and session.
 * Adjusts the session slots to the mentee's time zone.
 * @param mentorSessionRequest Object containing mentorID and sessionID
 * @returns Adjusted calendar slots
 */
export const fetchCalendarSession = async (mentorSessionRequest: { mentorID: number; sessionID: number }) => {
    console.log('kurwa no ')
    const {mentorID, sessionID} = mentorSessionRequest;

    const response = await axios.get<CalendarSlot[]>(`/api/1.0/mentor/${mentorID}/sessions/${sessionID}`);
    return response.data;
};

/**
 * Fetches calendar mentorship events for a specific mentor and session.
 * Parses the data into Slot objects.
 * @param mentorId ID of the mentor
 * @param sessionId ID of the session
 * @returns Parsed slots
 */
export const fetchCalendarMentorship = async (mentorId: string, sessionId: string) => {
    const {data} = await axios.get<SessionCalendarEventResponse[]>(`/api/1.0/mentor/${mentorId}/sessions/${sessionId}`);
    return dataParser(data);
};