import React, {createContext} from 'react';

export const AuthContext = createContext({});

const AuthContextProvider = (props) => {
    const value = {};
    return (
        <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
    );
};

export default AuthContextProvider;
