import React, {useState} from "react";
import {MentorData} from "../../MentorProfileEditPage";
import FormInputText from "../../../../../components/_form/FormInputText/FormInputText";
import {MentorNewsletterFormInput} from "@customTypes/mentor";
import {EmptyState} from "../../../../../components/EmptyState";
import {
    UserEditNewsletterSection
} from "../../../../../components/_grouped/user-edit-section/UserEditNewsletterSection";
import {updateUserNewsletter} from "@services/mentor/mentorSettingProfileService";
import {Control, FormState, useForm} from "react-hook-form";
import FormInputSelectIntegration from "../../../../../components/_form/FormInputSelect/FormInputSelectIntegration";
import SecureTextField from "../../../../../components/_form/FormInputText/SecureTextField";
import newsletterService from "@services/newsletter/newsletterService";

type Props = {
    mentorData: MentorData;
};

// const newsletterProviders = ["MAILCHIMP", "MAILERLITE", "SENDINBLUE"].map(value => ({
//     value,
//     label: value,
// }));


export const MentorEditNewsletter = ({mentorData}: Props) => {
    const [isEditing, setIsEditing] = useState<boolean>(mentorData?.newsletterActive ?? false);

    const {control, formState, handleSubmit} = useForm<MentorNewsletterFormInput>({
        defaultValues: {
            newsletterDescription: mentorData?.newsletterDescription || '',
            newsletterStatute: mentorData?.newsletterStatute || '',
            newsletterApiKey: mentorData?.newsletterApiKey || '',
            newsletterProvider:  ''
        },
    });

    const onSubmit = async (data: MentorNewsletterFormInput) => {
        try {
            await updateUserNewsletter(data);
            window.location.reload();
        } catch (error) {
            console.error("Failed to update personal data", error);
        }
    };


    return (
        <UserEditNewsletterSection
            title="Dodaj newsletter"
            subtitle="Daj możliwość zapisania się na newsletter swoim mentee"
            onSubmit={handleSubmit(onSubmit)}
            isEditing={isEditing}
            onStartEditing={() => setIsEditing(true)}
        >
            {isEditing ? (
                <>
                    <SecureTextField<MentorNewsletterFormInput>
                        name="newsletterApiKey"
                        control={control as unknown as Control<MentorNewsletterFormInput>}
                        formState={formState as unknown as FormState<MentorNewsletterFormInput>}
                        placeholder="Wpisz klucz API od dostawcy newslettera"
                        label="API KEY"
                        controllerProps={{
                            rules: {
                                required: "Klucz API jest wymagany"
                            }
                        }}
                    />

                    <FormInputSelectIntegration<MentorNewsletterFormInput>
                        control={control}
                        formState={formState}
                        label=""
                        name="newsletterProvider"
                        getOptions={newsletterService.fetchNewsletterProviders}
                        controllerProps={{
                            rules: {
                                required: "Wybór dostawcy newslettera jest wymagany"
                            }
                        }}
                    />

                    <FormInputText<MentorNewsletterFormInput>
                        name="newsletterDescription"
                        control={control as unknown as Control<MentorNewsletterFormInput>}
                        formState={formState as unknown as FormState<MentorNewsletterFormInput>}
                        controllerProps={{
                            rules: {
                                validate: {
                                    maxWords: (value: string | boolean | null) => {
                                        if (typeof value !== "string") {
                                            return true;
                                        }
                                        const wordCount = value
                                            .split(/\s+/)
                                            .filter((word) => word.length > 0).length;
                                        return wordCount <= 50 || "Maksymalnie 50 słów";
                                    },
                                },
                            },
                        }}
                        inputProps={{
                            placeholder:
                                mentorData?.newsletterDescription || "np. Dołącz, jeżeli chcesz odkryć więcej o UX Designie",
                            multiline: true,
                            minRows: 6,
                        }}
                        label="Krótki opis"
                    />

                    <FormInputText<MentorNewsletterFormInput>
                        name="newsletterStatute"
                        control={control as unknown as Control<MentorNewsletterFormInput>}
                        formState={formState as unknown as FormState<MentorNewsletterFormInput>}
                        inputProps={{
                            placeholder: mentorData?.newsletterStatute || 'Aby dodać newsletter konieczne jest zamieszczenie regulaminu',
                            multiline: true,
                            minRows: 6,
                        }}
                        label="Regulamin newslettera"
                    />
                </>
            ) : (
                <EmptyState text="Nie znaleziono żadnych aktywnych subskrypcji"/>
            )}
        </UserEditNewsletterSection>
    );
};