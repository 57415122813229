import axios from "axios";
import {CombinedData, MentorshipData} from "@customTypes/mentorship";


export const createSessionCheckout = async (sessionData: CombinedData) => {
    return await axios.post('/api/1.0/stripe/create-session-checkout', sessionData);
}

export const createMentoringCheckout = async (mentorshipData: MentorshipData) => {
    return await axios.post('/api/1.0/stripe/create-mentoring-checkout', mentorshipData);
};