// Libraries
import React, {useEffect, useState} from "react";
import classNames from "classnames";
import {Path, useLocation, useParams} from "react-router-dom";
import {useDispatch} from "react-redux";

// Components
import {defaultInput} from "src/components/Input/Input";
import {Title} from "src/components/typography";

// Styles
import styles from "./BookForm.module.scss";
// Types
import {TitleTag, TitleVariant} from "src/components/typography/Title/Title";
import {ServiceSession} from "@customTypes/order";
import {fetchCalendarSession} from "@services/calendar/calendarService";
import WeeklyCalendarPicker, {
    CalendarEvent,
} from "../../../../../components/WeeklyCalendarPicker/WeeklyCalendarPicker";
import {CalendarSlot} from "@customTypes/calendarSlot";
import {calculateOriginalHour} from "@services/calendar/calculateHourService";
import {convertToMenteeTime, formatTime, prepareEvent} from "@services/calendar/timeZoneService";
import {LocationStateType} from "@customTypes/locationStateType";
import {BookFormProps} from "@customTypes/bookFormProps";


export const Calendar = (props: BookFormProps) => {
    const dispatch = useDispatch();
    const {id} = useParams();
    const {selectTermHandler} = props;
    const location = useLocation();
    const locationState = location.state as LocationStateType;
    const sessionData = locationState.opt;
    const [currentEvent, setCurrentEvent] = useState<null | number>(null);
    const [hour, setHour] = useState<null | string>(null);
    const [term, setTerm] = useState<null | Date>(null);
    const [combinedData, setCombinedData] = useState<CalendarEvent[]>([]);

    useEffect(() => {
        dispatch({
            type: "UPDATE_BOOK_FORM",
            payload: {
                calendarEventId: currentEvent,
                hour: hour,
                term: term,
                menteeTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                mentorTimezone: sessionData?.timeZone || null,
            },
        });
    });
    useEffect(() => {
        if (id) {
            fetchCalendarSession({
                mentorID: sessionData?.mentorID,
                sessionID: Number.parseInt(sessionData?.id),
            })
                .then((res) => {
                    const menteeTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Get mentee timezone
                    const events: CalendarEvent[] = [];
                    res.forEach((item: CalendarSlot) => {
                        try {
                            const mentorTimeZone = item.mentorTimeZone || "UTC"; // Get mentor timezone
                            const mentorDate = new Date(item.sessionDate);
                            const [hour, minute] = item.hour.split(":").map(Number);
                            mentorDate.setHours(hour, minute, 0, 0);

                            const menteeDate = convertToMenteeTime(mentorDate, mentorTimeZone, menteeTimeZone);
                            const title = formatTime(menteeDate);
                            events.push(prepareEvent(title, menteeDate, item));

                        } catch (error) {
                            console.error("Slot processing error:", item, error);
                        }
                    });
                    setCombinedData(events);
                })
                .catch((error) => {
                    console.error("Error while retrieving data from server:", error);
                });
        }
    }, [id, sessionData?.id, sessionData?.mentorID]);

    const [form, setForm] = useState({
        term: defaultInput,
        topic: defaultInput,
        email: defaultInput,
        nip: defaultInput,
        phone: defaultInput,
        guests: {
            ...defaultInput,
            value: {
                guest0: {
                    name: defaultInput,
                    email: defaultInput,
                    message: defaultInput,
                },
            },
        },
        policy: {...defaultInput, value: false},
    });

    useEffect(() => {
        if (currentEvent !== null) {
            updateFormHandler("term", new Date());
        }
    }, [currentEvent]);

    const updateFormHandler = (name: string, value: any) => {
        setForm({...form, [name]: value});
    };

    const onEventClick = (event: CalendarEvent) => {
        setCurrentEvent(event.id);
        selectTermHandler(event.start);
        updateFormHandler("term", event.start);
        setTerm(event.start);
        setHour(event.title);
    };

    return (
        <section className={styles.wrapper}>
            <Title
                classes={classNames(styles.title, styles.titleMt0)}
                tag={TitleTag.h3}
                variant={TitleVariant.standard}
            >
                Wybierz termin i godzinę sesji
            </Title>
            <WeeklyCalendarPicker
                onEventClick={onEventClick}
                selectedEventsId={currentEvent ? [currentEvent] : null}
                events={combinedData}
            />
        </section>
    );
};
