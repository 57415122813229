import React, {useState} from "react";
import {useForm, Controller} from "react-hook-form";
import styles from "./MentorNewsletter.module.scss";

import Button, {ButtonVariant} from "../../../../../../components/Button/Button";
import TextField from "@mui/material/TextField";
import Checkbox from "../../../../../../components/Checkbox/Checkbox";
import {LottiAnimation} from "../../../../../../components/LottieAnimation/Lottie";
import {subscribeMenteeToMentorNewsletter} from "@services/newsletter/newsletterService";


export interface MentorNewsletterSubscriptionProps {
    mentorNewsletterData: MentorNewsletterData;
    mentorId?: string;
}

export interface MentorNewsletterData {
    title: string;
    description: string;
    newsletterActive: boolean
    newsletterStatute: string
    mentorId: number

}

export interface MentorNewsletterProps {
    isDesktop?: boolean;
    className?: string;
    mentorNewsletterData?: MentorNewsletterData;
}

export interface SubscriptionFormData {
    email: string;
    acceptRegulation: boolean;
    mentorId: string;

}

export interface MenteeNewsletterDto {
    email: string;
    mentorId: string;
}



export const MentorNewsletterSubscription = ({
                                                 mentorNewsletterData,
                                             }: MentorNewsletterSubscriptionProps) => {
    const {control, handleSubmit} = useForm<SubscriptionFormData>({
        defaultValues: {
            email: "",
            acceptRegulation: false
        },
    });
    const [isSuccess, setIsSuccess] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const RegulationPopup = ({onClose}: { onClose: () => void }) => {
        return (
            <div className={styles.popupOverlay}>
                <div className={styles.popupContent}>
                    <h3 className={styles.popupTitle}>Regulamin Newslettera</h3>
                    <p className={styles.newsletterDescription}>
                        {mentorNewsletterData?.newsletterStatute}
                    </p>
                    {/*<button onClick={onClose}>Zamknij</button>*/}
                </div>
            </div>
        );
    };

    const onSubmit = async (data: MenteeNewsletterDto) => {
        const newsletterDto: MenteeNewsletterDto = {
            email: data.email,
            mentorId: mentorNewsletterData.mentorId.toString()
        };

        await subscribeMenteeToMentorNewsletter(newsletterDto);
        setIsSuccess(true);
    };


    const handleTogglePopup = () => {
        setIsPopupOpen(prev => !prev);
    };


    const handleClosePopup = () => {
        setIsPopupOpen(false);
    };

    if (isSuccess) {
        return (
            <div className={styles.container}>
                <div className={styles.lottieWrapper}>
                    <LottiAnimation/>
                </div>
                <p className={styles.description}>
                    Twoja subskrypcja newslettera powiodła się! Dzięki za zapisanie się.
                </p>
            </div>
        );
    }

    return (
        <form className={styles.newsletterContainer} onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.header}>
                <p className={styles.newsletterDescription}>{mentorNewsletterData.description}</p>
            </div>

            <Controller
                name="email"
                control={control}
                rules={{
                    required: "Podanie emaila jest wymagane",
                    pattern: {
                        value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
                        message: "Podaj poprawny adres email",
                    },
                }}
                render={({field, fieldState}) => (
                    <TextField
                        {...field}
                        label=""
                        variant="outlined"
                        error={!!fieldState.error}
                        helperText={fieldState.error ? fieldState.error.message : ""}
                        fullWidth
                    />
                )}
            />

            <Controller
                name="acceptRegulation"
                control={control}
                rules={{required: "Musisz zaakceptować regulamin newslettera"}}
                render={({field, fieldState}) => (
                    <div className={styles.checkboxContainer}>
                        <Checkbox
                            id="acceptRegulation"
                            name="acceptRegulation"
                            label=""
                            value={field.value}
                            valueChangeHandler={(
                                name: string,
                                valueCh: { value: boolean; errorMessage?: string; isValid: boolean }
                            ) => {
                                field.onChange(valueCh.value);
                            }}
                        />
                        <div className={styles.labelAndError}><span className={styles.checkboxLabel}>Akceptuję{" "}
                            <span className={styles.regulationLink} onClick={handleTogglePopup}>regulamin</span>{" "}newslettera</span>
                            {fieldState.error && (
                                <p className={styles.error}>{fieldState.error.message}</p>
                            )}
                        </div>
                    </div>
                )}
            />

            {isPopupOpen && <RegulationPopup onClose={handleClosePopup}/>}

            <Button
                type="submit"
                variant={ButtonVariant.PrimaryLight}
                fontVariant="button-md"
                fullWidth
            >
                Zapisz się
            </Button>
        </form>
    );
};