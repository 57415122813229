// Libraries
import React from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";

import styles from "./MentorsList.module.scss";

import {buildMentorLink} from "../../utils";
import {MentorListingCard, MentorListingCardSkeleton,} from "../../../../../components/Cards/MentorListingCard";
import {FiltersSelected} from "@customTypes/filterTag";
import {Mentor} from "@customTypes/mentor";
import {Free} from "@icons/Free";

type Props = {
  error?: string;
  hasNextPage: boolean;
  mentors: Mentor[];
  pending: boolean;
  handleLoadMore: () => void;
  filters: Partial<FiltersSelected>
};

export const MentorsList = ({
  error,
  hasNextPage,
  mentors,
  pending,
  filters,
  handleLoadMore,
}: Props) => {
  const [sentryRef] = useInfiniteScroll({
    loading: pending,
    hasNextPage,
    onLoadMore: handleLoadMore,
    disabled: !!error,
    rootMargin: "0px 0px 400px 0px",
  });



  return (
      <div className={styles.mentorsList}>
        {mentors?.map((item: Mentor) => (
          <MentorListingCard
            filters={filters}
            key={item.mentorId}
            avatar_url={item.avatarUrl}
            description={item.description}
            fullName={item.firstName + " " + item.lastName}
            id={item.mentorId}
            company={item.company}
            link={buildMentorLink(item)}
            price={
              item.price === 0 ? (
                  <div className={styles.infoCell}>
                    <Free className={styles.Free} />
                  </div>
              ) : `${item.price} zł`
            }
            profession={item.profession}
            reviewsAvgRate={String(item.averageRating)}
            reviewsCount={String(item.reviewsCount)}
            special={item.special}
            specialVariant={item.specialVariant}
            skill={item.skills}
            categories={item.categories}
            title={item.title}
          />
        ))}
        {!error && (pending || hasNextPage) ? (
          <div ref={pending ? undefined : sentryRef}>
            <MentorListingCardSkeleton />
          </div>
        ) : null}
        {error && (
          <div className={styles.errorMsg}>
            <p>{error}</p>
          </div>
        )}
      </div>
  );
};
