import React from "react";

import {useForm} from "react-hook-form";
import styles from "./styles.module.scss";

import getAvailableSkillsService from "src/services/skills/getAvailableSkills.service";
import getAvailableCategoriesService from "src/services/categories/getAvailableCategories.service";
import getAvailableLanguage from "../../../../../services/language/getAvailableLanguage.service";
import getAvailableTimezone from "@services/timezone/getAvailableTimezone.service";
import {MentorData} from "../../MentorProfileEditPage";
import {UserEditSection} from "../../../../../components/_grouped";
import FormInputText from "../../../../../components/_form/FormInputText/FormInputText";
import FormAutocompleteDynamic from "../../../../../components/_form/FormAutocompleteDynamic/FormAutocompleteDynamic";
import FormCheckboxesOptionsDynamic
    from "../../../../../components/_form/FormCheckboxesDynamic/FormCheckboxesOptionsDynamic";
import {MentorEditProfileFormInput} from "@customTypes/mentor";
import {updateUserProfile} from "@services/mentor/mentorSettingProfileService";
import FormInputSelectTimeZone from "../../../../../components/_form/FormInputSelect/FormInputSelectTimeZone";

type Props = {
    mentorData: MentorData;
};

export const MentorEditSectionProfile = ({mentorData}: Props) => {
    const {control, formState, handleSubmit} =
        useForm<MentorEditProfileFormInput>({
            defaultValues: {
                heading: '',
                profession: '',
                company: '',
                biography: '',
                skill:mentorData?.skill || [],
                timezone: mentorData?.timeZone || '',
                language: mentorData?.language  || [],
                categories: mentorData?.mentorCategory || [],
            },
        });


    const inputProps = {
        formState: formState,
        control: control,
        controllerProps: {}
    };

    const onSubmit = async (data: MentorEditProfileFormInput) => {
        try {
            const mentorEditSection: MentorEditProfileFormInput = {
                heading: data.heading,
                profession: data.profession,
                company: data.company,
                biography: data.biography,
                skill: data.skill,
                timezone: data.timezone,
                language: data.language,
                categories: data.categories,
            };
            await updateUserProfile(mentorEditSection);
            // window.location.reload();
            window.location.href = window.location.href;


        } catch (error) {
            console.error('Failed to update personal data', error);
        }
    };

    return (
        <UserEditSection
            title="Profil"
            subtitle="Zaktualizuj swoje portfolio oraz biografię."
            onClose={() => {
                console.log("onClose");
            }}
            onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.Inputs}>
                <FormInputText<MentorEditProfileFormInput>
                    {...inputProps}
                    label="Nagłówek Twojego profilu"
                    inputProps={{placeholder: mentorData?.intro || "Wprowadź nagłowek Twojego profilu"}}
                    name="heading"
                />
                <FormInputText<MentorEditProfileFormInput>
                    {...inputProps}
                    label="Zawód"
                    inputProps={{placeholder: mentorData?.jobPosition || 'Wprowadź zawód'}}

                    name='profession'
                />
                <FormInputText<MentorEditProfileFormInput>
                    {...inputProps}
                    label="Firma"
                    inputProps={{placeholder: mentorData?.company || "Wprowadź nazwę firmy gdzie pracujesz"}}
                    name='company'
                />
                <FormInputText<MentorEditProfileFormInput>
                    {...inputProps}
                    label="Bio"
                    inputProps={{
                        placeholder: mentorData?.description || "Wprowadź biografię",
                        multiline: true,
                        minRows: 6,
                    }}
                    name='biography'
                />
                <FormAutocompleteDynamic<MentorEditProfileFormInput>
                    {...inputProps}
                    label="Umiejętności"
                    name='skill'
                    defaultValue={mentorData?.skill || []}
                    getOptions={getAvailableSkillsService}
                />
                <FormCheckboxesOptionsDynamic<MentorEditProfileFormInput>
                    {...inputProps}
                    label="Kategorie"
                    name='categories'
                    getOptions={getAvailableCategoriesService}
                />
                <FormInputSelectTimeZone<MentorEditProfileFormInput>
                    {...inputProps}
                    label=""
                    name='timezone'
                    getOptions={getAvailableTimezone}

                />
                <FormCheckboxesOptionsDynamic<MentorEditProfileFormInput>
                    {...inputProps}
                    label="Język mentoringu"
                    name='language'
                    getOptions={getAvailableLanguage}
                />
            </div>
        </UserEditSection>
    );
};