import React, {ReactNode} from "react";
import clx from "classnames";
import styles from "./styles.module.scss";
import {Typography} from "@mui/material";
import {palette} from "src/styles/theme";
import Button, {ButtonVariant} from "../../Button/Button";

type Props = {
    title?: string;
    subtitle?: string;
    children?: ReactNode;
    disabledSubmit?: boolean;
    pending?: boolean;
    onSubmit?: (e: React.FormEvent<HTMLFormElement>) => void;
    isEditing?: boolean;
    onStartEditing?: () => void;
};

export const UserEditNewsletterSection = ({
                                              title,
                                              subtitle,
                                              children,
                                              disabledSubmit,
                                              pending,
                                              onSubmit,
                                              isEditing = false,
                                              onStartEditing,
                                          }: Props) => {
    return (
        <form className={styles.section} onSubmit={onSubmit}>
            <div className={styles.colTitle}>
                {title && (
                    <Typography variant="buttonLg" color="secondary">
                        {title}
                    </Typography>
                )}
                {subtitle && (
                    <Typography
                        variant="caption"
                        color={palette.base[60]}
                        style={{marginTop: "4px", display: "block"}}
                    >
                        {subtitle}
                    </Typography>
                )}
            </div>
            <div className={styles.colContent}>
                {children && <div className={styles.Content}>{children}</div>}
                <div className={styles.centeredButton}>
                    {isEditing ? (
                        <Button
                            style={{marginLeft: "auto", marginRight: "20px"}}
                            className={clx(styles.btn, styles.BtnStyledOutline)}
                            variant={ButtonVariant.Primary}
                            disabled={disabledSubmit || pending}
                            type="submit"
                            fontVariant="button-md"
                        >
                            Zapisz zmiany
                        </Button>
                    ) : (
                        <Button
                            onClick={(e: { preventDefault: () => void; }) => {
                                e.preventDefault();
                                onStartEditing?.();
                            }}
                            style={{transform: "translateY(-40px)"}}
                            className={clx(styles.btn, styles.BtnStyledOutline)}
                            variant={ButtonVariant.Outline}
                            type="button"
                            fontVariant="button-md"
                        >
                            Dodaj newsletter
                        </Button>
                    )}
                </div>
            </div>
        </form>
    );
};
