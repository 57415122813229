import axios, {AxiosResponse} from "axios";
import {VerificationFormInput} from "@customTypes/registerFlow";
import {VerifyEmailAddressDTO} from "@customTypes/verifyEmailAddressDTO";

export interface VerificationResponse {
    data: {
        message: string;
        success: boolean;
        errorCode: number;
    };
    status: string;
    message: string;
}


const parseDataForAPI = (inputData: VerificationFormInput, userId: string): VerifyEmailAddressDTO => {
    return { userId, code: Object.values(inputData).join('') };
}

const verifyEmailAddressService = async (
    inputData: VerificationFormInput,
    userId: string
): Promise<VerificationResponse> => {
    try {
        const response: AxiosResponse<VerificationResponse> = await axios.post(
            '/api/1.0/auth/verify/email-address',
            parseDataForAPI(inputData, userId)
        );
        return response.data; // Zwraca dane w formacie VerificationResponse
    } catch (error: any) {
        // Obsługa błędów
        if (error.response) {
            throw new Error(error.response.data.message || 'Unknown error occurred');
        }
        throw new Error('Network error occurred');
    }
}


export default verifyEmailAddressService;
