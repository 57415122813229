import axios from "axios";

type SingleSession = SessionDetails & {
    sessionTerm?: Date;
    sessionDuration?: number;
    mentor: Mentor;
    userEmail: string;
    timeZone: string;
    sessionName: string;
    hour: string
    fullName: string;
};

interface SessionDetails {
    sessionPrice: number;
    sessionType: string;
    sessionDescription: string;
}

type Mentor = {
    id: number;
    userName: string;
    avatarUrl: string;
    fullName: string;
    profession: string;
    reviewsAvgRate: number;
    reviewsCount: number;
};

export const getSessionOrderSummary = async (
    calendarEventId: string | number,
    retryCount = 0
): Promise<SingleSession> => {
    // Maximum number of retry attempts
    if (retryCount > 10) {
        throw new Error("Maximum retry attempts reached");
    }

    // If it's the first attempt, wait longer to allow backend processing
    if (retryCount === 0) {
        await new Promise(resolve => setTimeout(resolve, 2000));
    }

    try {
        const response = await axios.get(`/api/1.0/order/session/${calendarEventId}`);
        const data = response?.data || {};

        // Check if data is still being processed
        if (data.status === "processing") {
            // Wait and retry
            await new Promise(resolve => setTimeout(resolve, 1500));
            return getSessionOrderSummary(calendarEventId, retryCount + 1);
        }

        // Process normal data
        const terms = Array.isArray(data.terms)
            ? data.terms.map((item: any) => new Date(item.startDateTime))
            : [];

        console.log('data: ', response?.data)

        return {
            userEmail: data.menteeEmail || '',
            mentor: {
                avatarUrl: data.avatarUrl || '',
                profession: data.jobPosition || 'TEST',
                id: 1,
                userName: "some",
                fullName: `${data.mentorFirstName || ''} ${data.mentorLastName || ''}`.trim(),
                reviewsAvgRate: 4.2,
                reviewsCount: 10,
            },
            sessionPrice: data.sessionPrice || 700,
            sessionType: data.sessionName || '',
            sessionName: '',
            sessionDescription: data.description || '',
            sessionTerm: terms.length > 0 ? terms[0] : null,
            sessionDuration: data?.sessionDuration || 70,
            timeZone: data.timeZone || '',
            hour: data?.hour || '',
            fullName: `${data.mentorFirstName || ''} ${data.mentorLastName || ''}`.trim(),
        };
    } catch (error) {
        // For non-404 errors, rethrow
        if (axios.isAxiosError(error) && error.response?.status !== 404) {
            throw error;
        }

        // For 404, retry after a delay
        await new Promise(resolve => setTimeout(resolve, 1500));
        return getSessionOrderSummary(calendarEventId, retryCount + 1);
    }
};