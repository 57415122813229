import axios from "axios";
import {ContactServiceOutput} from "@services/contact/contact.types";

export const sendMentorMessage = async ({
                                            username,
                                            email,
                                            message,
                                        }: {
    username: string;
    email: string;
    message: string;
}): Promise<ContactServiceOutput> => {
    try {
        const response = await axios.post(`/api/1.0/mentors/${username}/messages`, {
            email,
            message,
        });

        if (response.status === 200 || response.status === 201) {
            return {
                success: true,
                successMessage: "Dziękujemy! Wiadomość została wysłana.",
            };
        } else {
            return {
                success: false,
                errorMessage: "Nie udało się wysłać wiadomości. Spróbuj ponownie później.",
            };
        }
    } catch (error) {
        console.error("Błąd przy wysyłaniu wiadomości: ", error);
        return {
            success: false,
            errorMessage: "Nie udało się wysłać wiadomości z powodu błędu serwera.",
        };
    }
};
