import axios from "axios";
import {DropdownOption} from "@customTypes/dropdownOption";
import {
    MenteeNewsletterDto
} from "../../pages/unauthorized/MentorProfile/components/content/newsletter/MentorNewsletterSubscription";

export type EmailNewsletterProvider = {
    key: string | number;
    value: string;
};

/**
 * Fetches available newsletter providers from the API
 * @returns {Promise<DropdownOption[]>} List of newsletter providers formatted for dropdown
 */
export const fetchNewsletterProviders = async (): Promise<DropdownOption[]> => {
    try {
        const response = await axios.get('/api/1.0/newsletter/fetch-all-providers');
        return response.data.map((t: EmailNewsletterProvider) => ({
            value: t.key,
            label: t.value,
        }));
    } catch (error) {
        console.error('Error fetching newsletter providers:', error);
        const newsletterProviders = ["MAILCHIMP", "MAILERLITE", "SENDINBLUE"];
        return newsletterProviders.map(provider => ({
            value: provider,
            label: provider,
        }));
    }
};

/**
 * Subscribes a mentee to a mentor's newsletter
 * @returns {Promise<any>} Response from the API
 * @param subscriptionData
 */
export const subscribeMenteeToMentorNewsletter = async (
    subscriptionData: MenteeNewsletterDto
): Promise<any> => {
    try {
        const response = await axios.post('/api/1.0/newsletter/subscribe', subscriptionData)
        return response.data;
    } catch (error) {
        console.error('Error subscribing mentee to mentor newsletter:', error);
        throw error;
    }
};

/**
 * Unsubscribes a mentee from a mentor's newsletter
 * @param {string} menteeEmail - Email of the mentee to unsubscribe
 * @param {string} mentorId - ID of the mentor whose newsletter the mentee is unsubscribing from
 * @returns {Promise<any>} Response from the API
 */
export const unsubscribeMenteeFromMentorNewsletter = async (
    menteeEmail: string,
    mentorId: string
): Promise<any> => {
    try {
        const response = await axios.post('/api/1.0/newsletter/unsubscribe', {
            email: menteeEmail,
            mentorId: mentorId
        });
        return response.data;
    } catch (error) {
        console.error('Error unsubscribing mentee from mentor newsletter:', error);
        throw error;
    }
};

/**
 * Checks if a mentee is subscribed to a mentor's newsletter
 * @param {string} menteeEmail - Email of the mentee
 * @param {string} mentorId - ID of the mentor
 * @returns {Promise<boolean>} True if subscribed, false otherwise
 */
export const checkMenteeSubscription = async (
    menteeEmail: string,
    mentorId: string
): Promise<boolean> => {
    try {
        const response = await axios.get('/api/1.0/newsletter/check-subscription', {
            params: {
                email: menteeEmail,
                mentorId: mentorId
            }
        });
        return response.data.isSubscribed;
    } catch (error) {
        console.error('Error checking mentee subscription status:', error);
        return false; // Assume not subscribed on error
    }
};

// Export all functions as a service object
const newsletterService = {
    fetchNewsletterProviders,
    subscribeMenteeToMentorNewsletter,
    unsubscribeMenteeFromMentorNewsletter,
    checkMenteeSubscription
};

export default newsletterService;