import {Terms} from "@customTypes/terms";

export const UPDATE_TERMS = "UPDATE_TERMS";

export const termsInitialState: Terms = {
  categories: [],
  skills: [],
  services: [],
  topics:[],
  languages:[]
};
