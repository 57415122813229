import React, {useState} from "react";
import {Controller, Control, FormState, FieldValues, Path} from "react-hook-form";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

export interface MentorNewsletterFormInput {
    newsletterApiKey?: string;
    newsletterLink?: string;
    // inne pola...
}

interface Props<T extends FieldValues> {
    name: Path<T>;
    control: Control<T>;
    formState: FormState<T>;
    placeholder?: string;
    label: string;
    controllerProps?: {
        rules?: any;
        [key: string]: any;
    };
}

const SecureTextField = <T extends FieldValues>({
                                                    name,
                                                    control,
                                                    label,
                                                    placeholder,
                                                    controllerProps = {}
                                                }: Props<T>) => {
    const isSecureField = name === "newsletterApiKey";

    const [showValue, setShowValue] = useState(false);
    const fieldType = isSecureField ? (showValue ? "text" : "password") : "text";

    return (
        <Controller
            name={name}
            control={control}
            rules={controllerProps.rules} // Dodaj tę linię
            render={({field, fieldState}) => (
                <TextField
                    {...field}
                    type={fieldType}
                    placeholder={placeholder}
                    label={label}
                    variant="outlined"
                    error={!!fieldState.error}
                    helperText={fieldState.error ? fieldState.error.message : ""}
                    fullWidth
                    InputProps={
                        isSecureField
                            ? {
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => setShowValue((prev) => !prev)}
                                            edge="end"
                                            aria-label="toggle API key visibility"
                                        >
                                            {showValue ? <VisibilityOffIcon/> : <VisibilityIcon/>}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }
                            : undefined
                    }
                />
            )}
        />
    );
};

export default SecureTextField;
