import axios from "axios";
import {
    MentorEditLinksFormInput,
    MentorEditProfileFormInput,
    MentorNewsletterFormInput,
    MentorPersonalData
} from "@customTypes/mentor";

export const updateUserPersonalData = async (personalData: MentorPersonalData) => {
    try {
        const formData = new FormData();

        const profilePhoto = personalData.avatarUrl ? personalData.avatarUrl[0] : null;
        const profilePhotoCover = personalData.coverUrl ? personalData.coverUrl[0] : null;

        if (profilePhoto != null) {
            formData.append('profilePhoto', profilePhoto);
        }

        if (profilePhotoCover != null) {
            formData.append('profilePhotoCover', profilePhotoCover);
        }

        formData.append('formData', new Blob([JSON.stringify(personalData)], {
            type: 'application/json'
        }));

        return await axios.patch('/api/1.0/user/setting/personal-data', formData);

    } catch (error) {
        console.error(error);
        throw new Error("Failed to update personal data");
    }
};

export const updateUserProfile = async (mentorEditSection: MentorEditProfileFormInput) => {
    console.log('odpowiedz: updateUserProfile')
    try {
        const response = await axios.patch<string>('/api/1.0/user/setting/profile', mentorEditSection);

        if (response.status >= 200 && response.status < 300) {
            console.log('Profile update successful');
            return response.data;
        } else {
            console.error('API returned an error:', response);
            throw new Error(`API error: ${response.status}`);
        }
    } catch (error) {
        console.error('Error updating profile:', error);

        if (axios.isAxiosError(error)) {
            console.error('Axios error details:', error.response?.data);
            throw new Error(`Failed to update profile: ${error.response?.status || 'network error'}`);
        }

        throw new Error("Failed to update personal data");
    }
};

export const updateUserSocialLinks = async (mentorEditLinksSocial: MentorEditLinksFormInput) => {
    try {

        return await axios.patch<string>('/api/1.0/user/setting/social', mentorEditLinksSocial);

    } catch (error) {
        console.error(error);
        throw new Error("Failed to update personal data");
    }
}


export const updateUserNewsletter = async (mentorNewsletterFormInput: MentorNewsletterFormInput) => {
    try {

        return await axios.patch<string>('/api/1.0/user/setting/newsletter', mentorNewsletterFormInput);

    } catch (error) {
        console.error(error);
        throw new Error("Failed to update personal data");
    }
}

