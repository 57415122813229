import moment from "moment-timezone";
import {CalendarSlot} from "@customTypes/calendarSlot";

/**
 * Converts a date and time from the mentor's timezone to the mentee's timezone.
 *
 * @param mentorDate - The date object in the mentor's timezone.
 * @param mentorTimeZone - The timezone of the mentor (e.g., "Europe/Warsaw").
 * @param menteeTimeZone - The timezone of the mentee (e.g., "America/New_York").
 * @returns The adjusted date object in the mentee's timezone.
 */
export const convertToMenteeTime = (
    mentorDate: Date,
    mentorTimeZone: string,
    menteeTimeZone: string
): Date => {
    const mentorOffset = moment.tz(mentorTimeZone).utcOffset(); // Mentor's UTC offset in minutes
    const menteeOffset = moment.tz(menteeTimeZone).utcOffset(); // Mentee's UTC offset in minutes
    const differenceInHours = (menteeOffset - mentorOffset) / 60; // Time difference in hours

    return new Date(mentorDate.getTime() + differenceInHours * 60 * 60 * 1000); // Adjust the mentor date to the mentee's timezone
};

/**
 * Formats a date object to a string in "HH:mm" format.
 *
 * @param date - The date object to format.
 * @returns The formatted time string in "HH:mm".
 */
export const formatTime = (date: Date): string =>
    `${date.getHours().toString().padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}`;

export function prepareEvent(title: string, menteeDate: Date, item: CalendarSlot) {
    return {
        id: item.calendarEventId,
        title,
        allDay: true,
        start: menteeDate,
        end: new Date(menteeDate.getTime() + 60 * 60 * 1000),
        available: item.available,
    };
}
