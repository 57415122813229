import {DropdownOption} from "@customTypes/dropdownOption";
import axios from "axios";

export type TimeZone = {
    key: string | number;
    value: string;
};

export const getAvailableTimezone = async (): Promise<DropdownOption[]> => {
    try {
        const response = await axios.get('/api/1.0/timezones');
        return response.data.map((t: TimeZone) => ({
            value: t.key,
            label: t.value,
        }));
    } catch (error) {
        console.error('Error fetching timezones:', error);
        throw error;
    }
};
export default getAvailableTimezone;