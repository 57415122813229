import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { MentorServiceCard } from "src/components/Cards/MentorServiceCard";
import Button, { ButtonVariant } from "src/components/Button/Button";
import { Loader } from "src/components/_grouped/loader";
import { getSessionOrderSummary } from "@services/order/orderService";
import { useSelector } from "react-redux";
import { getRole } from "../../../redux/selectors/authSelectors";
import Container from "src/components/Container/Container";
import { Tag } from "@customTypes/tags";
import { SelectedDate } from "src/pages/app/BookSession/components";

import styles from "./OrderConfirm.module.scss";
import paths from "../../../paths";
import GuestBenefits from "./GuestBenefits";

type SessionData = {
  userEmail: string;
  sessionName: string;
  sessionPrice: number;
  sessionType: string;
  sessionDescription: string;
  sessionTerm?: Date;
  sessionDuration?: number;
  fullName:string;
  mentor: {
    id: number;
    userName: string;
    avatarUrl: string;
    fullName: string;
    profession: string;
    reviewsAvgRate: number;
    reviewsCount: number;
  };
  timeZone: string;
};

export const OrderConfirmPage = () => {
  const { id } = useParams<{ id: string | "" }>();
  const location = useLocation();
  const [session, setSession] = useState<SessionData | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [loadingPhase, setLoadingPhase] = useState<"initial" | "fetching" | "completed">("initial");
  const [error, setError] = useState<string | null>(null);
  const role = useSelector(getRole) || "GUEST";

  const queryParams = new URLSearchParams(location.search);
  const paymentJustCompleted = queryParams.get('payment_just_completed') === 'true';

  useEffect(() => {
    // Zawsze pokazuj animację sukcesu przez minimum 3 sekundy
    setLoadingPhase("initial");
    const successTimer = setTimeout(() => {
      setLoadingPhase("fetching");
      if (id) fetchSessionData(id);
    }, paymentJustCompleted ? 3000 : 1000); // Dłuższe opóźnienie, jeśli właśnie zakończono płatność

    return () => clearTimeout(successTimer);
  }, [id, paymentJustCompleted]);

  const fetchSessionData = async (id: string) => {
    const maxAttempts = 8;
    let attempts = 0;

    const fetchWithRetry = async () => {
      try {
        const data = await getSessionOrderSummary(id, attempts);
        setSession(data);
        setLoadingPhase("completed");
        setIsLoading(false);
      } catch (err) {
        attempts++;
        console.log(`Attempt ${attempts}/${maxAttempts}`);

        if (attempts < maxAttempts) {
          setTimeout(fetchWithRetry, 1500);
        } else {
          setError("Nie udało się pobrać danych sesji. Spróbuj odświeżyć stronę.");
          setLoadingPhase("completed");
          setIsLoading(false);
        }
      }
    };

    fetchWithRetry();
  };

  // Render animacji sukcesu
  if (loadingPhase === "initial") {
    return (
        <Container as={Tag.Section}>
          <div className={styles.successAnimation}>
            <div className={styles.checkmark}>
              <div className={styles.checkmarkCircle}></div>
              <div className={styles.checkmarkStem}></div>
              <div className={styles.checkmarkKick}></div>
            </div>
            <h2 className={styles.successTitle}>Dziękujemy za Twoje zamówienie!</h2>
            <p className={styles.successSubtitle}>Przygotowujemy potwierdzenie...</p>
          </div>
        </Container>
    );
  }

  // Render ładowania danych
  if (loadingPhase === "fetching" || isLoading) {
    return <Loader overlay spinner />;
  }

  // Render błędu
  if (error || !session) {
    return (
        <Container as={Tag.Section}>
          <div className={styles.errorContainer}>
            <h3 className={styles.title}>Wystąpił problem</h3>
            <p className={styles.subtitle}>{error || "Nie udało się załadować danych zamówienia."}</p>
            <Button href={paths.home} variant={ButtonVariant.PrimaryLight}>
              Powrót do strony głównej
            </Button>
          </div>
        </Container>
    );
  }

  // Formatowanie daty i czasu
  const formattedDate = session.sessionTerm
      ? session.sessionTerm.toLocaleDateString()
      : "";
  const formattedTime = session.sessionTerm
      ? session.sessionTerm.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      })
      : "";

  // Render głównego widoku potwierdzenia
  return (
      <main>
        <Container as={Tag.Section}>
          <div className={styles.flexContainer}>
            <div className={styles.boxOuter}>
              <div className={styles.boxMobile}>
                <h3 className={styles.title}>Spotkanie zostało zarezerwowane</h3>
                <p className={styles.subtitle}>
                  Co teraz? Otrzymasz e-mail z potwierdzeniem spotkania, który będzie zawierał link do spotkania.
                  Sprawdź swoją skrzynkę pocztową, aby uzyskać więcej szczegółów.{" "}
                  <span className={styles.mail}>{session?.userEmail}</span>
                </p>
                <Button
                    href={paths.home}
                    classes={styles.btnMobile}
                    variant={ButtonVariant.PrimaryLight}
                >
                  Powrót do strony głównej
                </Button>
              </div>
              <MentorServiceCard
                  meetingForm="video"
                  maxAttendees={5}
                  information="Link do spotkania przyjdzie na e-mail lub po zalogowaniu aplikacji w kalendarzu"
                  avatar_url={session?.mentor?.avatarUrl}
                  description={session?.sessionDescription}
                  fullName={session?.fullName}
                  profession={session?.mentor?.profession}
                  reviewsAvgRate={String(session?.mentor?.reviewsAvgRate)}
                  reviewsCount={String(session?.mentor?.reviewsCount)}
                  title={session?.sessionType}
                  initialDescriptionHeight={90}
                  servicePrice={session?.sessionPrice}
                  serviceDuration={session?.sessionDuration || 45}
                  timeZone={session?.timeZone}
                  serviceType="session"
              />
              <SelectedDate
                  selectedDate={formattedDate}
                  selectedTime={formattedTime}
              />
              <Button
                  href={paths.home}
                  classes={styles.btnDesktop}
                  variant={ButtonVariant.PrimaryLight}
              >
                Powrót do strony głównej
              </Button>
            </div>
            {role === "GUEST" && <GuestBenefits />}
          </div>
        </Container>
      </main>
  );
};